
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    items: require("@/data/mines/bespokemines.json"),
    minesItems: require("@/data/mines/minesoptions.json"),
    minesItems2: require("@/data/mines/minesoptions2.json"),
  }),
});
