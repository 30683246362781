
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    items: require("@/data/double/bespokedouble.json"),
    doubleItems: require("@/data/double/doubleoption.json"),
    doubleItems2: require("@/data/double/doubleoption2.json"),
  }),
});
