import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg"},_vm._l((_vm.items),function(project,idx){return _c('div',{key:idx,staticClass:"purple-section",attrs:{"id":project.tab}},[(
        _vm.getPermissions.username !== 'fusion' || project.tab !== 'About us'
      )?_c('div',{staticClass:"section-jg--title",attrs:{"id":project.description}},[_c('h2',[_vm._v(_vm._s(project.tab))])]):_vm._e(),_c(VContainer,{attrs:{"id":"max-width"}},[(
          _vm.getPermissions.username !== 'fusion' || project.tab !== 'About us'
        )?_c('hr',{staticClass:"section-jg--title"}):_vm._e(),_c('h4',{staticClass:"subtitle"},[_vm._v(_vm._s(project.subtitle))]),(project.subtitle)?_c('div',{staticStyle:{"margin-bottom":"30px"}}):_vm._e(),(project.tab === 'Branded Content - Fast Games')?_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},_vm._l((project.content),function(item,idx){return _c(VCol,{key:idx,staticClass:"align-center",attrs:{"xs":"6","sm":"6","md":"6","lg":"3"}},[_c('div',[(item.hasChip)?_c(VChip,{staticClass:"chip-card",attrs:{"color":"primary","overlap":""}},[_c('span',[_vm._v(_vm._s(item.chip || ""))])]):_vm._e(),_c('img',{staticClass:"image-game-ips",class:item.class,attrs:{"src":require(`@/assets/${item.img}`)}})],1)])}),1):_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},_vm._l((project.content),function(item,idx){return _c(VCol,{key:idx,staticClass:"align-center",attrs:{"xs":"3","sm":"3","md":"3","lg":"3"}},[(item.hasChip)?_c(VChip,{staticClass:"chip-card",attrs:{"color":"primary"}},[_c('span',[_vm._v(_vm._s(item.chip || ""))])]):_vm._e(),_c('a',{attrs:{"href":item.href}},[_c('img',{staticClass:"image-game",class:item.class,attrs:{"src":require(`@/assets/${item.img}`)}})])],1)}),1),_c('h4',{staticClass:"footer"},[_vm._v(_vm._s(project.footer))]),(project.isCommingSoon)?_c('div',[_c('div',[_c('div',[_c('video-grid')],1)])]):_vm._e(),(project.isClientMarketing)?_c('div',[_c('div',[_c('div',[_c('VideoGridMotograu')],1)])]):_vm._e(),(project.hasPdf && _vm.getPermissions.username !== 'fusion')?_c('div',[_c('div',[_c('download-pdf-vue',{attrs:{"pdf-path":project.pdfLink}})],1)]):_vm._e()],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }