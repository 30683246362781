
import Vue from "vue";
import Catalog from "../components/Catalog.vue";

export default Vue.extend({
  name: "MyHome",

  components: {
    Catalog,
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
});
