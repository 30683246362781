<template>
  <div>
    <div class="confidential">
      <div class="container">
        <div>
          This page holds private and confidential information belonging to Jogo
          Global Ltd and may not be disclosed to any third party or used for any
          other purpose without written permission from Jogo Global Ltd.This
          page and its contents may not be reproduced, redistributed, passed on,
          or the contents otherwise divulged, directly or indirectly, to any
          other person or published in whole or in part for any purpose without
          written permission from Jogo Global Ltd ©
        </div>
      </div>
    </div>

    <!-- Header -->
    <header :class="{ isOpen: !menuOpen, isScrolled: isScrolled }">
      <div class="container">
        <figure class="brand">
          <a href="/" rel="noopener noreferrer">
            <img
              src="../assets/JG-logo.svg"
              alt="Jogo Global Logo"
              srcset="../assets/JG-logo.svg"
            />
          </a>
        </figure>
        <nav class="menu">
          <input type="checkbox" id="menuToggle" v-model="menuOpen" />
          <label for="menuToggle" class="menu-icon">
            <v-icon v-if="!menuOpen" large color="white darken-2">
              mdi-menu
            </v-icon>
            <v-icon v-else large color="white darken-2"> mdi-close </v-icon>
          </label>
          <ul :class="{ menuOpen: menuOpen }">
            <a
              v-for="item in items"
              :key="'item' + item.tab"
              :href="'#' + item.tab"
              >{{ item.tab }}</a
            >
          </ul>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "LoggedHeader",
  data() {
    return {
      items: require("@/data/catalog.json"),
      menuOpen: false,
      isScrolled: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleScroll() {
      if (window.scrollY > 50) {
        this.isScrolled = true;
      }
      if (window.scrollY < 50) {
        this.isScrolled = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.menuOpen {
  height: 650px;
  display: block !important;
  transition: ease-in;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}
.hide {
  display: none;
}
.isScrolled {
  top: 0 !important;
  transition: 0.5s ease-in;
}
body:not(.scrolled) .confidential {
  display: block;
}
.confidential {
  background: #c61789;
  width: 100%;
  height: 99px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block; /* Initially hidden */
  div {
    padding: 10px 20px;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 20px;
    @media screen and (max-width: 768px) {
      padding: 4px 10px;
      font-size: 10px;
      line-height: 10px;
    }
    @media screen and (min-width: 1023px) and (max-width: 1100px) {
      padding: 6px 10px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
header {
  width: 100%;
  position: fixed;
  top: 99px;
  left: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  min-height: 50px;
  line-height: 50px;
  background: linear-gradient(0deg, #19042d, #19042d);
  color: white;
  text-align: center;
  overflow: hidden;
  align-items: center; /* Centralizar verticalmente */
  border-bottom: 4px solid transparent;
  border-image: linear-gradient(
    90deg,
    #faac4b 0%,
    #f9a74a 5.12%,
    #f89947 11.12%,
    #f58142 17.55%,
    #f2613b 24.29%,
    #ed3732 31.2%,
    #ed3431 31.61%,
    #e8303d 36.89%,
    #da265b 46.33%,
    #c5168c 58.5%,
    #bc1e91 62.08%,
    #a3339d 68.87%,
    #7b55b2 76.95%,
    #4384cf 87.04%,
    #00bcf1 98.77%
  );
  border-image-slice: 1;
}
.brand {
  width: auto;
  height: 100%;
  float: left;
}
.brand img {
  height: 50px;
  width: 150px;
}
.menu {
  width: calc(100% - 150px);
  height: 100%;
  min-height: 50px;
  float: right;
}
.menu ul {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
}
.menu ul a {
  padding: 0 8px;
  height: inherit;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
}

@media screen and (min-width: 1250px) and (max-width: 1440px) {
  .menu ul a {
    font-size: 0.9rem;
  }
}
@media screen and (min-width: 1441px) and (max-width: 1800px) {
  .menu {
    width: calc(100% - 250px);
  }
  .menu ul a {
    font-size: 0.9rem;
  }
  .brand img {
    height: 50px;
    width: 250px;
  }
}
@media screen and (min-width: 1801px) and (max-width: 2000px) {
  .menu {
    width: calc(100% - 300px);
  }
  .menu ul a {
    font-size: 1.2rem;
  }
  .brand img {
    height: 50px;
    width: 300px;
  }
}
@media screen and (min-width: 2001px) {
  .menu {
    width: calc(100% - 400px);
  }
  .menu ul a {
    font-size: 1.3rem;
  }
  .brand img {
    height: 50px;
    width: 400px;
  }
}
.menu ul a:hover {
  color: #fff;
  border-radius: 6px;
  background-color: #7a09e3;
}
#menuToggle {
  display: none;
}
.menu-icon {
  display: none;
}
@media screen and (min-width: 1031px) and (max-width: 1105px) {
  .brand img {
    height: 50px;
    width: 75px;
  }
  .menu {
    width: calc(100% - 75px);
    height: 100%;
    min-height: 50px;
    float: right;
  }
}
@media screen and (min-width: 1031px) {
  .container {
    max-width: 100% !important;
  }
  .menu ul a {
    padding: 0 8px;
  }
}
@media screen and (max-width: 1030px) {
  .confidential {
    display: none;
  }
  .isOpen {
    height: 75px !important;
  }

  .menu {
    width: 100%;
    height: auto;
  }
  .menu ul {
    display: none;
    overflow: hidden;
    -webkit-transition: max-height 0.3s;
    -moz-transition: max-height 0.3s;
    -ms-transition: max-height 0.3s;
    -o-transition: max-height 0.3s;
    transition: max-height 0.3s;
  }
  .menu ul a {
    text-align: center;
    width: 100%;
    background: linear-gradient(0deg, #19042d, #19042d);
    color: #fff;
  }
  .menu-icon {
    width: 100px;
    height: inherit;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 80px;
  }
  .menu-icon i {
    font-size: 1.7em;
  }
}
</style>
