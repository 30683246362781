
import Vue from "vue";
// import ForImage from "../components/ForImage.vue";

export default Vue.extend({
  name: "MyHome",

  components: {
    // ForImage,
  },
});
