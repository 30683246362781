
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    items: require("@/data/boom/bespokeboom.json"),
    boomItems: require("@/data/boom/boomoptions.json"),
    boomItems2: require("@/data/boom/boomoptions2.json"),
  }),
});
