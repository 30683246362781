import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg",attrs:{"id":"section"}},[_c('div',{staticClass:"purple-section",staticStyle:{"margin-bottom":"-50px"}},[_vm._m(0),_c(VContainer,{attrs:{"id":"max-width"}},[_c('hr',{staticClass:"section-jg--title"}),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VContainer,[_c('PdfJg',{attrs:{"pdf-path":"Jogo Global - Fast Games Q3 2024.pdf"}})],1)],1)],1)],1),_c('div',{staticClass:"purple-section"},[_vm._m(1),_c(VContainer,{attrs:{"id":"max-width"}},[_c('hr',{staticClass:"section-jg--title"}),_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},_vm._l((_vm.items),function(project,idx){return _c(VCol,{key:idx,staticClass:"align-center",attrs:{"xs":"6","sm":"6","md":"4","lg":"3"}},[_c('a',{attrs:{"href":project.href}},[_c('img',{staticClass:"image-game",class:project.class,attrs:{"src":require(`@/assets/${project.img}`)}})])])}),1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg--title",attrs:{"id":"Crash"}},[_c('h2',[_vm._v("FAST GAME OVERVIEW")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-jg--title",attrs:{"id":"Crash"}},[_c('h2',[_vm._v("CRASH")])])
}]

export { render, staticRenderFns }