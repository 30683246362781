
import Vue from "vue";
import VideoGrid from "./VideoGrid.vue";
import VideoGridMotograu from "./VideoGridMotograu.vue";
import DownloadPdfVue from "./Download-pdf.vue";
export default Vue.extend({
  components: {
    VideoGrid,
    VideoGridMotograu,
    DownloadPdfVue,
  },
  data: () => ({
    items: require("@/data/catalog/catalog.json"),
  }),
  computed: {
    getPermissions: function () {
      const storedUser = localStorage.getItem("currentUser");
      if (!storedUser) {
        return {};
      }
      // const currentUser = JSON.parse(storedUser);
      return JSON.parse(storedUser);
    },
  },
});
